<template>
  <div class="step-2-wrap" v-loading="loading">
    <el-form
      class="step-2"
      ref="form"
      :model="team"
      :rules="rules"
      label-width="140px"
    >
      <el-form-item label="队伍名称" prop="teamName">
        <el-input v-model="team.teamName" show-word-limit :maxlength="13" />
      </el-form-item>
      <el-form-item label="领队姓名" prop="leaderName">
        <el-input v-model="team.leaderName" />
      </el-form-item>
      <el-form-item label="领队联系电话" prop="leaderPhone">
        <el-input v-model="team.leaderPhone" show-word-limit maxlength="11" />
      </el-form-item>
      <el-form-item label="套路项目教练姓名" prop="coachName">
        <el-input v-model="team.coachName" />
      </el-form-item>
      <el-form-item label="套路项目教练手机" prop="coachPhone">
        <el-input v-model="team.coachPhone" show-word-limit maxlength="11" />
      </el-form-item>
      <div v-if="showMoreCoach" ref="newCoach">
        <el-form-item label="推手项目教练姓名" prop="coachName2">
          <el-input v-model="team.coachName2" />
        </el-form-item>
        <el-form-item label="推手项目教练手机" prop="coachPhone2">
          <el-input v-model="team.coachPhone2" show-word-limit maxlength="11" />
        </el-form-item>
      </div>
      <div v-if="showMoreBtn" class="step-btn-group">
        <el-button
          style="justify-content:center"
          type="primary"
          @click="addCoach"
          >{{ showBtnTips }}</el-button
        >
      </div>
    </el-form>
    <div class="step-btn-group">
      <el-button type="primary" @click="nextStep()">下一步</el-button>
    </div>
  </div>
</template>

<script>
import { saveTeam, getTeam, saveTeamAdmin, getTeamAdmin } from "@/api";
export default {
  name: "Step2",
  components: {},
  props: {},
  data() {
    function validatePhone(rule, value, callback) {
      let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
      // if (value === "") {
      //   callback(new Error("请输入手机号码"));
      // } else
      if (value && !reg.test(value)) {
        callback(new Error("请输入正确的手机号码"));
      } else {
        callback();
      }
    }
    return {
      loading: true,
      team: {
        teamName: "", // 队伍名称
        leaderName: "", // 领队姓名
        leaderPhone: "", // 领队联系电话
        coachName: "", // 教练姓名
        coachPhone: "", // 教练联系电话
        coachName2: "", // 教练姓名2
        coachPhone2: "" // 教练联系电话2
      },
      rules: {
        stationName: [
          { required: true, message: "请选择站点", trigger: "change" }
        ],
        group: [
          { required: true, message: "请选择竞赛组别", trigger: "change" }
        ],
        teamName: [
          { required: true, message: "请输入队伍名称", trigger: "change" }
        ],
        leaderName: [
          { required: true, message: "请输入领队姓名", trigger: "blur" }
        ],
        leaderPhone: [
          { required: true, validator: validatePhone, trigger: "change" }
        ],
        // coachName: [
        //   { required: true, message: "请输入教练姓名", trigger: "blur" }
        // ],
        coachPhone: [
          { required: false, validator: validatePhone, trigger: "change" }
        ],
        // coachName2: [
        //   { required: true, message: "请输入教练姓名", trigger: "blur" }
        // ],
        coachPhone2: [
          { required: false, validator: validatePhone, trigger: "change" }
        ],

        contestants: [
          { validator: this.validateContestants, trigger: "change" }
        ],
        itemType: [
          { required: true, message: "请选择项目类型", trigger: "change" }
        ],
        itemRoutine: [
          { required: true, message: "请填写项目名称", trigger: "blur" }
        ]
      },
      // 允许提交或修改
      canNotClick: false,
      // 站点
      station: [],
      template: {
        contestants: [],
        itemType: "",
        itemRoutine: ""
      },
      yuanContestants: [], //存储未修改前的队伍成员
      showBtnTips: "新增教练信息",
      showMoreCoach: true,
      showMoreBtn: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getTeam();
  },
  mounted() {},
  methods: {
    getTeam() {
      this.loading = true;
      if (this.$store.state.user.authority === 0) {
        // 用户
        getTeam()
          .then(res => {
            if (res.data.team) {
              this.$store.commit("SET_STATUS", res.data.status);
              this.team = res.data.team;
              if (res.data.team.coachName2) {
                this.showMoreCoach = true;
                this.showBtnTips = "删除教练信息";
              }
            }
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      } else {
        // 管理员
        getTeamAdmin()
          .then(res => {
            if (res.data.team) {
              this.$store.commit("SET_STATUS", res.data.status);
              this.team = res.data.team;
            }
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            console.error(err);
          });
      }
    },
    nextStep() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const request =
            this.$store.state.user.authority === 0 ? saveTeam : saveTeamAdmin;
          if (!this.team.coachName && !this.team.coachName2) {
            this.$message.error("至少输入一位教练信息");
            return false;
          }
          if (
            (this.team.coachName && !this.team.coachPhone) ||
            (this.team.coachName2 && !this.team.coachPhone2)
          ) {
            this.$message.error("请输入教练的手机号码");
            return false;
          }
          request(this.team)
            .then(res => {
              if (res.data.code === 200) {
                this.$store.dispatch("STEP_NEXT", {
                  router: this.$router,
                  route: this.$route
                });
              }
            })
            .catch(err => {
              this.$alert(err, "系统消息");
              console.error(err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    addCoach() {
      if (!this.showMoreCoach) {
        this.showMoreCoach = true;
        this.showBtnTips = "删除教练信息";
        this.team.coachName2 = "";
        this.team.coachPhone2 = "";
      } else {
        this.showMoreCoach = false;
        this.showBtnTips = "新增教练信息";
        this.team.coachName2 = "";
        this.team.coachPhone2 = "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
.step-2 {
  width: 100%;
  max-width: 550px;
  margin: 20px auto 0;
}
.step-btn-group {
  margin-bottom: 20px;
}
</style>
